import { BehaviorSubject } from "rxjs";

const store = {
  debug: false,
  state: {},
  createState(prop, value, options) {
    this.state[prop] = {};
    if (options && options.persist) {
      saveState(prop, value, true);
      this.state[prop].persist = true;
    }
    this.state[prop].value = value;
    this.state[prop].sub = new BehaviorSubject(value);
  },
  setState(prop, value) {
    if (!this.state[prop]) {
      this.createState(prop, value);
    }
    this.state[prop].value = value;
    this.state[prop].sub.next(value);
    if (this.state[prop].persist) {
      saveState(prop, value, false);
    }
    if (this.debug) {
      console.log(`State Changed: ${prop} `, value);
    }
  },
  getState(prop) {
    return this.state[prop]?.value;
  },
  observeState(prop) {
    return this.state[prop]?.sub;
  },
  removeState(prop) {
    removeState(prop);
  },
  clear() {
    this.state = {};
    clear();
  },
};

function debounce(func, timeout) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

function saveState(key, value, isInitialSet) {
  const doStateSaving = () => {
    try {
      const serializedState = JSON.stringify(value);
      console.log("Persisting to localStorage " + key, value);
      window.localStorage.setItem(key, serializedState);
    } catch {
      // Ignore write errors
    }
  };

  if (isInitialSet) {
    // Here we don't debounce saving state since it's the initial set
    // so it's called only once and we need our storage to be updated
    // right away
    doStateSaving();
  } else {
    // Here we debounce saving state because it's the update and this function
    // is called every time the store state changes. However, it should not
    // be called too often because it triggers the expensive `JSON.stringify` operation.
    const DEBOUNCE_TIME = 1000; // In milliseconds
    // Debounce doStateSaving before calling it
    const processStateSaving = debounce(doStateSaving, DEBOUNCE_TIME);
    processStateSaving(); // save State
  }
}

function loadState(key, noState) {
  try {
    const serializedState = window.localStorage.getItem(key);
    if (serializedState === null) {
      // No state saved
      return noState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    // Failed to load state
    return undefined;
  }
}

function removeState(key) {
  window.localStorage.removeItem(key);
}

function clear() {
  window.localStorage.clear();
}

store.createState("isAnon", false, { persist: false });
store.createState("userMenuOpen", false, { persist: false });
store.createState("privacyOpen", false, { persist: false });
store.createState("contactsOpen", false, { persist: false });
store.createState("profileOpen", false, { persist: false });
store.createState("lastDialogKey", "", { persist: false });
store.createState("order", "", { persist: true });
store.observeState("order").subscribe((newOrder) => {
  console.log("Order changed:", newOrder);
  console.trace("Order change stack trace:");
});

store.createState("phoneNumber", loadState("phoneNumber", ""), {
  persist: true,
});
store.createState("shop", loadState("shop", null), { persist: true });
store.createState("address", loadState("address", null), { persist: true });

export default store;

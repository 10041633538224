import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import Footer from "../components/Footer.tsx";
import Header from "../components/Header.tsx";
import Image from "../components/Image.tsx";
import Selectable from "../components/Selectable.tsx";
import { FoodType } from "../models/food-type.ts";
import { Rating as StoredRating } from "../models/rating.tsx";
import { Shop } from "../models/shop.ts";
import type { Address } from "../models/submodels/address.ts";
import scuverService from "../services/scuver.service.ts";
import locationHelper from "../utils/location-helper.ts";
import { MyTime } from "../utils/time-helper.ts";
import { Loading } from "./Loading.tsx";
import shopHelper from "../utils/shop-helper.ts";
import addressService from "../services/address.service.ts";

type Rating = {
  rate: number;
  rated: number;
};

export const Home = () => {
  const [address, setAddress] = useState<Address>({} as Address);
  const [orderBy, setOrderBy] = useState<string>("time");
  const [ratings, setRatings] = useState<Map<string, Rating>>(null);
  const [foodTypes, setFoodTypes] = useState<Array<FoodType>>([]);
  const [foodType, setFoodType] = useState({
    uid: "HJCuHXsSpPnWrWktuOdH",
  } as FoodType);
  const [shops, setShops] = useState<Array<Shop>>([]);
  const [gotShops, setGotShops] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    console.log("useEffect Homio");
    setLoading(true);

    scuverService.getCollection("food-types").then((f) => setFoodTypes(f));

    const addressSubscription = addressService
      .observeCurrentAddress()
      .subscribe((a) => {
        console.log("address in homio", a);
        if (a && a?.addressLine1) {
          setAddress(a);
          if (!isInitialized) {
            setIsInitialized(true);
          }
        } else {
          navigate("/welcome");
        }
      });

    return () => addressSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    searchShops();
  }, [address?.addressLine1, foodType?.uid, searchParams.get("search")]);

  useEffect(() => {
    if (!shops.length) return;

    const orderedShops = [...shops].sort(sortShops);
    setShops(orderedShops);
  }, [orderBy, shops.length]);

  function sortShops(a, b) {
    // console.log('orderBy', orderBy)
    if (a.suspendOrders || shopHelper.closestOrderingTime(a) === "closed") {
      return 1;
    }
    if (b.suspendOrders || shopHelper.closestOrderingTime(b) === "closed") {
      return -1;
    }
    if (orderBy === "rating") {
      const aRating = ratings.get(a.uid)?.rate;
      const bRating = ratings.get(b.uid)?.rate;
      if (!aRating) return 1;
      if (!bRating) return -1;
      return aRating >= bRating ? -1 : 1;
    } else if (orderBy === "price") {
      if (!a.averagePrice) return 1;
      if (!b.averagePrice) return -1;
      return a.averagePrice > b.averagePrice
        ? 1
        : a.averagePrice < b.averagePrice
        ? -1
        : 0;
    } else {
      return calcTime(a) > calcTime(b) ? 1 : -1;
    }
  }

  function calcTime(shop: Shop): number {
    const shopTime = MyTime.parse(shop.preparationTime);
    const distance = locationHelper.getRadiusDistanceInKm(
      shop.address?.coordinates,
      address?.coordinates
    );
    return shopTime.toMinutes() + distance * 2;
  }

  async function searchShops() {
    setLoading(true);
    try {
      const storedShops = await scuverService.getCollection("shops");
      // console.log('storedShops', storedShops);
      // console.log('s.foodTypesId', storedShops.find(s => s?.name.indexOf('McD') !== -1)?.foodTypesId);
      let filteredShops = storedShops.filter(
        (s) =>
          !foodType ||
          !foodType.uid ||
          foodType.uid === "HJCuHXsSpPnWrWktuOdH" ||
          s.foodTypesId?.find((u) => u === foodType.uid)
      );
      filteredShops = filteredShops.filter((s) => !!s.uid);
      filteredShops = filteredShops.filter((s) => s?.name !== "Test");
      filteredShops = filteredShops.filter(
        (s) => !s.address || !!s.address?.coordinates?.latitude
      );
      filteredShops = filteredShops.filter((s) => !s.suspendOrders);
      filteredShops = filteredShops.filter((s) => {
        try {
          const distance = locationHelper.getRadiusDistanceInKm(
            s.address?.coordinates,
            address?.coordinates
          );
          return !address || distance <= s.deliveryCoverage;
        } catch (e) {
          console.log("error in filteredShops", e);
          return true;
        }
      });
      filteredShops = filteredShops.sort(sortShops);
      if (searchParams.get("search")) {
        const searchText = searchParams.get("search");
        const foundShops = [];
        for (const shop of filteredShops) {
          if (!foundShops.find((s) => s.uid === shop.uid)) {
            if (shop?.name.toLowerCase().includes(searchText.toLowerCase())) {
              foundShops.push(shop);
            } else {
              const categories = await scuverService.getRecordsByProperty(
                "shop-categories",
                "shopId",
                "==",
                shop.uid
              );
              for (const category of categories) {
                if (!foundShops.find((s) => s.uid === shop.uid)) {
                  for (const item of category.items) {
                    if (
                      item?.name
                        ?.toLowerCase()
                        .includes(searchText?.toLowerCase())
                    ) {
                      foundShops.push(shop);
                      break;
                    }
                  }
                }
              }
            }
          }
          history.pushState(
            {},
            null,
            window.location.href.substring(0, window.location.href.indexOf("?"))
          );
        }
        const orderedShops = foundShops.sort(sortShops);
        setShops([...orderedShops]);
      } else {
        const orderedShops = filteredShops.sort(sortShops);
        setShops([...orderedShops]);
      }
      setGotShops(true);
      populateRatings();
    } catch (error) {
      console.error("Error searching shops:", error);
    } finally {
      setLoading(false);
    }
  }

  async function populateRatings() {
    const newRatings = new Map<string, Rating>();
    scuverService.getCollection("ratings-and-pics").then((storedRatings) => {
      for (const shop of shops) {
        const storedRating: StoredRating = storedRatings.find(
          (r) => r.uid === shop.uid
        );
        if (storedRating) {
          const rating = {} as Rating;
          rating.rated = storedRating.ratingsSubmitted;
          rating.rate = storedRating.rating;
          newRatings.set(shop.uid, rating);
        }
      }
      setRatings(newRatings);
    });
  }

  function chooseShop(shop) {
    navigate(`/shop/${shop.uid}`);
  }

  return (
    <div className={"min-h-screen"}>
      <Header />
      <Loading show={loading} />
      <main className="flex w-[99vw] px-3 md:p-4 text-gray-800">
        <div className="flex flex-col w-full space-y-4">
          {/* <div className="w-full flex justify-between items-center">
            <div>

            </div>
          </div> */}
          <div className={"mt-2 xl:text-center"}>
            {foodTypes && foodTypes.length ? (
              <div
                className={
                  "py-4 space-x-4 text-center xl:flex xl:justify-between overflow-auto w-full whitespace-nowrap"
                }
              >
                {foodTypes
                  .sort((a, b) => (a.priority >= b.priority ? 1 : -1))
                  .map((foodType) => (
                    <div
                      key={foodType.uid}
                      className={
                        "inline-block text-center cursor-pointer hover:opacity-90"
                      }
                      onClick={() => setFoodType(foodType)}
                    >
                      <Image src={foodType.svgUrl} classes="w-16 h-16" />
                      <span className={"font-bold text-xs"}>
                        {foodType.names[0]}
                      </span>
                    </div>
                  ))}
              </div>
            ) : (
              ""
            )}
          </div>
          <hr className={"hidden md:block"} />
          <div className={"flex min-h-[47.1vh]"}>
            <div
              className={
                "hidden md:flex flex-col col-span-2 bg-gray-200 h-full w-2/12 rounded-2xl mr-8 p-8"
              }
            >
              <span className={"text-lg font-bold mb-6"}>{t("Order By")}</span>
              <Selectable
                checked={orderBy === "time"}
                label={t("Time")}
                onSelected={(e) => {
                  if ((e.target as any).checked) {
                    setOrderBy("time");
                  }
                }}
              />
              <Selectable
                checked={orderBy === "rating"}
                classes={"mt-2"}
                label={t("Rating")}
                onSelected={(e) => {
                  if ((e.target as any).checked) {
                    setOrderBy("rating");
                  }
                }}
              />
              <Selectable
                checked={orderBy === "price"}
                classes={"mt-2"}
                label={t("Price")}
                onSelected={(e) => {
                  if ((e.target as any).checked) {
                    setOrderBy("price");
                  }
                }}
              />
            </div>
            <div
              className={
                "w-full md:w-10/12 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4"
              }
            >
              {/*<div className={'grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 3xl:grid-cols-6 flex-wrap w-full md:w-10/12 min-h-[56.4vh]'}>*/}
              {shops && shops.length ? (
                shops.map((shop) => (
                  /*<div className={`flex flex-col md:mr-4 mb-6`} key={shop.uid} onClick={() => shopHelper.closestOrderingTime(shop) !== 'closed' && !shop.suspendOrders && chooseShop(shop)}>*/
                  <div
                    className={`flex flex-col md:mx-3 mb-6`}
                    key={shop.uid}
                    onClick={() => chooseShop(shop)}
                  >
                    {!shop.suspendOrders && shopHelper.isOpenForMeal(shop) ? (
                      ""
                    ) : (
                      <div
                        className={
                          "absolute cursor-pointer bg-gray-800 rounded-2xl text-white text-xs p-2 justify-self-center text-center self-center mt-[3vh] font-bold uppercase"
                        }
                      >
                        {shopHelper.closestOrderingTime(shop) === "suspended"
                          ? t("Orders Suspended")
                          : shopHelper.closestOrderingTime(shop) === "closed"
                          ? t("Closed for Today")
                          : t("Schedule To") +
                            ": " +
                            shopHelper.closestOrderingTime(shop)}
                      </div>
                    )}
                    <div
                      className={`rounded-2xl ${
                        shopHelper.closestOrderingTime(shop) !== "closed" &&
                        !shop.suspendOrders
                          ? "cursor-pointer"
                          : "opacity-20"
                      }`}
                    >
                      <Image
                        src={shop.photoUrl}
                        classes="w-full h-36 rounded-2xl object-cover border border-[#ccc]"
                      />
                    </div>
                    <div
                      className={`flex justify-between items-center px-1 ${
                        shopHelper.closestOrderingTime(shop) !== "closed" &&
                        !shop.suspendOrders
                          ? "cursor-pointer"
                          : "opacity-20"
                      }`}
                    >
                      <div className={"flex flex-col mt-3 w-10/12"}>
                        <span
                          className={"font-bold text-lg md:text-base truncate"}
                        >
                          {shop?.name}
                        </span>
                        <div className={"flex text-base"}>
                          {shop.averagePrice && (
                            <span>
                              €{shop.averagePrice.toFixed(0)}{" "}
                              <span className={"text-sm"}>(2pax.)</span>
                              &nbsp;•&nbsp;
                            </span>
                          )}
                          <span className={"text-gray-400"}>
                            {calcTime(shop)}-{calcTime(shop) + 15}min
                          </span>
                        </div>
                      </div>
                      {ratings?.get(shop.uid)?.rate &&
                      ratings?.get(shop.uid)?.rate > 3 ? (
                        <div
                          className={
                            "flex justify-center items-center bg-gray-200 rounded-full w-7 h-7 text-sm shadow-sm"
                          }
                        >
                          {ratings.get(shop.uid)?.rate.toFixed(1) || 0}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))
              ) : gotShops ? (
                <div className={"w-full"}>
                  <span className={"text-xl text-primary-500"}>
                    {t("No Results")}
                  </span>
                </div>
              ) : (
                <PacmanLoader color="rgb(32 156 149)" />
              )}
            </div>
          </div>
        </div>
        {/* <Notification /> */}
      </main>
      <Footer />
    </div>
  );
};

import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Order } from "../models/order";
import store from "../state-store.tsx";
import addressService from "./address.service.ts";
import scuverService from "./scuver.service.ts";

class OrderService {
  private currentOrder$ = new BehaviorSubject<Order>(null);

  constructor() {
    this.trackCurrentOrder();
    this.trackAddressChange();
  }

  // --------------------------------------------------------------------------------------------------------
  // Current Order Functions
  // --------------------------------------------------------------------------------------------------------

  private trackCurrentOrder() {
    store.observeState("order").subscribe((order) => {
      console.log("trackCurrentOrder order changed", order);
      this.currentOrder$.next(order);
    });
    // authService.observeCurrentUser().subscribe((user) => {
    //   console.log(
    //     "Order Service trackCurrentOrder user shop",
    //     user,
    //     store.getState("shop"),
    //   );

    //   this.currentOrderSub.unsubscribe();

    //   if (!user || !user.uid) return this.currentOrder$.next(null);
    //   this.currentOrderSub = this.observeUserOngoingOrder(
    //     user.uid,
    //     store.getState("shop")?.uid,
    //   ).subscribe((order) => {
    //     console.log('trackCurrentOrder observeUserOngoingOrder order changed', order);
    //     if (order) {
    //       order.shop = store.getState("shop");
    //       order.user = order.user && order.user.uid ? order.user : user;
    //     }
    //     this.currentOrder$.next(order);
    //   });
    // });
  }

  private trackAddressChange() {
    addressService.observeCurrentAddress().subscribe((a) => {
      const order = this.currentOrder$.getValue();
      if (order && a?.addressLine1) {
        order.address = a;
        this.updateOrder(order);
      }
    });
  }

  async setCurrentOrder(order: Order) {
    console.log("setCurrentOrder", order);
    if (order && order.uid && order.status === "being-created") {
      // order.subTotal = orderHelper.calculateSubTotal(order);
      // order.total = orderHelper.calculateOrderTotal(order);
      // console.log("setCurrentOrder updating order", order);
      // this.currentOrder$.next(order);
      // await this.removeOrdersBeingCreatedByUserExcept(order);
      // await this.updateOrder(order);
      await this.updateOrder(order);
    }
  }

  observeCurrentOrder(): Observable<Order> {
    return this.currentOrder$.asObservable();
  }

  observeServiceOrder(uid?: string): Observable<Order> {
    return scuverService.observeRecord("orders", uid);
  }

  // --------------------------------------------------------------------------------------------------------
  // Generic Firebase Functions
  // --------------------------------------------------------------------------------------------------------

  async getOrder(_uid?: string): Promise<Order> {
    //return scuverService.getRecord("orders", uid);
    return store.getState("order");
  }

  observeOrdersByUser(userId: string): Observable<Order[]> {
    return scuverService.observeRecordsByProperty(
      "orders",
      "user.uid",
      "==",
      userId,
    );
  }

  observeOrdersByShop(shopId: string): Observable<Order[]> {
    return scuverService.observeRecordsByProperty(
      "orders",
      "shop.uid",
      "==",
      shopId,
    );
  }

  getOrdersByUserAndShop(userId: string, shopId: string): Promise<Order[]> {
    return scuverService.getRecordsByProperties(
      "orders",
      ["user.uid", "shop.uid"],
      "==",
      [userId, shopId],
    );
  }

  getOrdersByUserAndShopAndTable(
    userId: string,
    shopId: string,
    table: number,
  ): Promise<Order[]> {
    return scuverService.getRecordsByProperties(
      "orders",
      ["user.uid", "shop.uid", "table"],
      "==",
      [userId, shopId, table],
    );
  }

  getOrdersByShopAndTable(shopId: string, table: number): Promise<Order[]> {
    return scuverService.getRecordsByProperties(
      "orders",
      ["shop.uid", "table"],
      "==",
      [shopId, table],
    );
  }

  async getOrderForThisUserBeingCreated(userId: string): Promise<Order> {
    const results = await scuverService.getRecordsByProperties(
      "orders",
      ["user.uid", "status"],
      "==",
      [userId, "being-created"],
    );
    return results.length ? results[results.length - 1] : null;
  }

  // observeUserOrderBeingCreated(userId: string, shopId: string): Observable<Order> {
  //   return scuverService.observeRecordByProperties('orders', ['user.uid', 'shop.uid', 'status'], '==', [userId, shopId, 'being-created'])
  //       .pipe(map((order: Order) => {
  //         console.log('observeOrderBeingCreated ', order);
  //         return order ? merge(new Order(), order) : null;
  //       }));
  // }

  observeUserOngoingOrder(userId: string, shopId: string): Observable<Order> {
    const props = ["user.uid", "status", "status"];
    const filterOps = ["==", "!=", "!="];
    const values = [userId, "completed", "cancelled"];
    if (shopId) {
      props.push("shop.uid");
      filterOps.push("==");
      values.push(shopId);
    }
    return scuverService
      .observeRecordByProperties("orders", props, filterOps, values)
      .pipe(
        map((order: Order) => {
          console.log("observeUserOngoingOrder ", order);
          return order ? (order as Order) : null;
        }),
      );
  }

  addOrder(order: Order): Promise<Order> {
    return scuverService.addOrUpdateRecord(
      "orders",
      JSON.parse(JSON.stringify(order)),
    ) as Promise<Order>;
  }

  async updateOrder(order: Order): Promise<Order> {
    try {
      // Deep clone the order to prevent reference issues
      const orderToUpdate = JSON.parse(JSON.stringify(order));
      
      // Only update BehaviorSubject if status hasn't changed
      const currentOrder = this.currentOrder$.getValue();
      if (!currentOrder || currentOrder.status === orderToUpdate.status) {
        this.currentOrder$.next(orderToUpdate);
      }
      
      // Make backend call
      const response = await scuverService.addOrUpdateRecord(
        "orders",
        orderToUpdate
      );
      
      let finalOrder: Order;
      
      if (response && response.acknowledged !== undefined) {
        // MongoDB acknowledgment - keep using our order
        finalOrder = orderToUpdate;
      } else if (response) {
        // Got back an updated order
        finalOrder = response as Order;
      } else {
        // Fallback to our order
        finalOrder = orderToUpdate;
      }
      
      // Only update state if the status matches to prevent navigation issues
      if (!currentOrder || currentOrder.status === finalOrder.status) {
        store.setState("order", finalOrder);
        this.currentOrder$.next(finalOrder);
      }
      
      return finalOrder;
    } catch (error) {
      console.error("Error updating order:", error);
      return order;
    }
  }

  removeOrder(uid: string) {
    store.removeState("order");
    return new Promise((resolve) => {
      console.log("Removing order ", uid);
      scuverService.removeOrder(uid).then((r) => {
        console.log("Removed order ", uid);
        this.currentOrder$.next(null);
        resolve(r);
      });
    });
  }

  observeOrder(_uid: string): Observable<Order> {
    return store.observeState("order");
    // return scuverService
    //   .observeRecord("orders", uid)
    //   .pipe(
    //     map((order: Order) => (order && order.uid ? (order as Order) : null)),
    //   );
  }

  // private removeOrdersBeingCreatedByUserExcept(order: Order) {
  //   // if (this.user && this.user.role === 'employee') {
  //   //   return scuverService.getRecordsByProperties(
  //   //       'orders',
  //   //       ['uid'    , 'user.uid'    , 'status' , 'table'      ],
  //   //       ['!='     , '=='          , '=='           , '=='],
  //   //       [order.uid, order.user.uid, 'being-created', order.table]
  //   //   ).then(records => {
  //   //     records.forEach(record => this.removeOrder(record.uid));
  //   //   });
  //   // } else
  //   if (order.user && order.user.uid) {
  //     return scuverService
  //       .getRecordsByProperties(
  //         "orders",
  //         ["uid", "user.uid", "status"],
  //         ["!=", "==", "=="],
  //         [order.uid, order.user.uid, "being-created"],
  //       )
  //       .then((records) => {
  //         records.forEach((record) => this.removeOrder(record.uid));
  //       });
  //   }
  // }

  saveAnonymousOrderBeforeLogin() {
    localStorage.setItem(
      "anonymousOrderBeforeLogin",
      this.currentOrder$?.getValue()?.uid,
    );
  }

  getAnonymousOrderBeforeLogin() {
    return localStorage.getItem("anonymousOrderBeforeLogin");
  }

  deleteAnonymousOrderBeforeLogin() {
    localStorage.removeItem("anonymousOrderBeforeLogin");
  }
}

export default new OrderService();

import Dialog from "./Dialog.tsx";
import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Address } from "../models/submodels/address.ts";
import { CheckIcon } from "@heroicons/react/24/solid";
import addressService from "../services/address.service.ts";
import Input from "./Input.tsx";
import { useTranslation } from "react-i18next";
import Button from "./Button.tsx";
import store from "../state-store.tsx";

export default function AddressSelection(props: {
  currentAddress: any;
  show: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose?: Function;
}) {
  const [addressLine, setAddressLine] = useState("");
  const [addressLineTwo, setAddressLineTwo] = useState("");
  const [addresses, setAddresses] = useState(new Array<Address>());
  const [selected, setSelected] = useState(null);
  const [showDialog, setShowDialog] = useState(props.show);
  const { t } = useTranslation();

  useEffect(() => {
    setShowDialog(props.show);
  }, [props.show]);

  useEffect(() => {
    if (props.currentAddress && props.currentAddress.length > 3) {
      setAddressLine(props.currentAddress);
      searchPlaces(props.currentAddress);
    } else {
      setAddressLine("");
    }
  }, [props.currentAddress]);

  useEffect(() => {
    if (addressLine && addressLine.length > 3) {
      const found = addresses.find((a) => a.addressLine1 === addressLine);
      if (found) {
        setSelected(found);
      }
    }
  }, [addresses, addressLine]);

  async function searchPlaces(value: string) {
    if (value && value.length > 3) {
      setAddresses(await addressService.getListOfPlaces(value));
    } else {
      setAddresses([]);
    }
  }

  function selectAddress(addr: Address) {
    console.log("address selection selectAddress addr", addr);
    setSelected(addr);
    if (addr?.addressLine1) {
      setAddressLine(addr.addressLine1);
    }
    if (addr?.addressLine2) {
      setAddressLineTwo(addr.addressLine2);
    }
  }

  useEffect(() => {
    if (
      selected &&
      selected !== "null" &&
      selected !== null &&
      selected !== undefined
    ) {
      addressSelected();
    }
  }, [selected]);

  function addressSelected() {
    console.log("addressSelected selected is", selected);
    setShowDialog(false);
    if (props.onClose) {
      props.onClose();
    }
    store.setState("lastDialogKey", "");
    if ((!selected || !selected?.placeId) && addresses.length) {
      addressService
        .getAddressUsingPlace(addresses[0].placeId || "")
        .then((add) => {
          add.addressLine2 = addressLineTwo;
          addressService.setAddress(add);
        });
    }
    if (selected?.placeId) {
      addressService
        .getAddressUsingPlace(selected.placeId || "")
        .then((add) => {
          add.addressLine2 = addressLineTwo;
          addressService.setAddress(add);
        });
    }
  }

  return (
    <>
      {/*<span className={'absolute bottom-32 left-0 text-4xl text-white z-50'}>showDialog Address: {showDialog.toString()}</span>*/}
      <Dialog
        key={"address"}
        show={showDialog}
        classes={"bg-gray-100 overflow-y-auto overflow-x-hidden"}
        onClose={() => {
          setShowDialog(false);
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <div className="flex flex-col overflow-y-auto">
          <div>
            <Input
              classes={"bg-[#eee]"}
              onFocus={(e) => (e.target as any).select()}
              initialValue={addressLine}
              placeholder={t("Enter delivery address")}
              onChange={(e) => {
                const value = (e.target as any).value || "";
                setAddressLine(value);
                searchPlaces(value);
              }}
            />
            <Input
              classes={"bg-[#eee]"}
              onFocus={(e) => (e.target as any).select()}
              initialValue={addressLineTwo}
              placeholder={t("Details (house number, floor, etc...)")}
              onChange={(e) => {
                const value = (e.target as any).value || "";
                setAddressLineTwo(value);
              }}
            />
          </div>
          <div className="w-full px-4 pt-8 pb-4">
            <div className="mx-auto w-full">
              <RadioGroup
                value={selected}
                onChange={(e) => {
                  selectAddress(e);
                }}
              >
                <RadioGroup.Label className="sr-only">Address</RadioGroup.Label>
                <div className="space-y-2">
                  {addresses.map((address) => (
                    <RadioGroup.Option
                      key={address.addressLine1}
                      value={address}
                      className={({ active, checked }) =>
                        `${
                          active || checked
                            ? "ring-2 ring-white ring-opacity-100 ring-offset-2 ring-offset-black-300"
                            : ""
                        } ${
                          checked ? "bg-gray-200 bg-opacity-100" : "bg-gray-50"
                        } relative flex cursor-pointer rounded-lg px-5 py-4 focus:outline-none border border-1 border-[#ccc]`
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <div className="flex w-full items-center justify-between">
                            <div className="flex items-center">
                              <div className="text-sm">
                                <RadioGroup.Label
                                  as="p"
                                  className={`font-medium ${
                                    checked ? "text-gray-900" : "text-gray-700"
                                  } ${active ? "" : ""}`}
                                >
                                  {address.addressLine1}
                                </RadioGroup.Label>
                              </div>
                            </div>
                            {checked && (
                              <div className="shrink-0 text-gray-900">
                                <CheckIcon className="h-6 w-6" />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
              <div className={"w-full flex justify-center mt-8"}>
                {selected && addressLine && addressLine.length > 3 && (
                  <Button
                    backgroundColor={"bg-primary-500"}
                    textColor={"white"}
                    text={t("Save")}
                    onClick={() => {
                      addressSelected();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

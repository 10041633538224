import {
  CheckCircleIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import loadGoogleMapsApi from "load-google-maps-api";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import cardIcon from "../assets/images/card-logo.png";
import mbwayIcon from "../assets/images/mbway.png";
import Footer from "../components/Footer.tsx";
import Image from "../components/Image.tsx";
import { GOOGLE_KEY } from "../constants";
import { Order } from "../models/order.ts";
import { Driver } from "../models/driver.ts";
import scuverService from "../services/scuver.service.ts";
import { MyMoment } from "../utils/time-helper.ts";
// import { useParams } from "react-router-dom";
import Header from "../components/Header.tsx";
import orderService from "../services/order.service.ts";

export const OrderStatusPage = () => {
  const [order, setOrder] = useState({} as Order);
  const [map, setMap] = useState(null);
  const { t } = useTranslation();
  // const { uid } = useParams();
  const googleMapRef = useRef(null);
  const scooterIcon =
    "https://firebasestorage.googleapis.com/v0/b/scuver-data.appspot.com/o/scooter.png?alt=media&token=8a23dd9a-63ca-42d6-b4b3-7180da6b7b9f";
  const storeIcon =
    "https://firebasestorage.googleapis.com/v0/b/scuver-data.appspot.com/o/store.png?alt=media&token=56558ca8-5904-40af-9aaa-1505d517e22c";
  const houseIcon =
    "https://firebasestorage.googleapis.com/v0/b/scuver-data.appspot.com/o/house.png?alt=media&token=697ba49f-4b33-4f5a-ad4b-6da5c6080fe1";
  const driverMarkerRef = useRef(null);

  let orderSubscription;
  let driverSubscription;

  const paymentMethods = [
    ...(order?.user?.cardToken
      ? [
          {
            value: "card",
            label: t("Previous Online Card"),
            iconImage: cardIcon,
          },
        ]
      : []),
    {
      value: "mbw",
      label: t("MBWay"),
      iconImage: mbwayIcon,
    },
    {
      value: "card",
      label: t("Card Online"),
      iconImage: cardIcon,
    },
    {
      value: "payment-on-delivery",
      label: t("Card on Delivery"),
      iconImage: cardIcon,
    },
  ];

  useEffect(() => {
    init();
  }, []);

  async function init() {
    // orderService.observeCurrentOrder().subscribe(o => {
    //   console.log('observed order', o);
    //   if (o) {
    //     setOrder(o);
    //   }
    // })

    // if (uid) {
    const currentOrder = await orderService.getOrder();
    setOrder(currentOrder);
    if (currentOrder?.uid) {
      orderService.observeServiceOrder(currentOrder.uid).subscribe((o) => {
        setOrder(o);
      });
    }
    // }

    // const storedOrder = await scuverService.getRecord('orders', uid || order?.uid || 'Naaa');
    // orderService.setCurrentOrder(storedOrder);
    // if (!storedOrder) {
    //   toast.error('Order not found. Encomenda não encontrada.')
    //   navigate('/');
    // }

    await initGoogle();
    const googleMap = initGoogleMap();
    setMap(googleMap);

    // orderSubscription = scuverService.observeRecord('orders', order.uid).subscribe(() => {
    //   scuverService.getRecord('orders', order.uid).then(savedOrder => updateOrder(savedOrder));
    // });

    (window as any).gtag("event", "conversion", {
      send_to: "AW-11191352282/8NCBCPjCpuIYENr3udgp",
      transaction_id: order.uid,
    });
  }

  function updateOrder(updatedOrder) {
    if (order.status === "pending" && updatedOrder.status === "viewed") {
      toast.success(
        `${updatedOrder.shop.name} ${t("is now preparing your order")}`
      );
    }
    if (
      updatedOrder.driver &&
      updatedOrder.driver.email &&
      !driverSubscription &&
      map
    ) {
      driverSubscription = scuverService
        .observeRecord("drivers", updatedOrder.driver?.uid)
        .subscribe((storedDriver: Driver) => {
          console.log("storedDriver", storedDriver);
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          const updatedRecently =
            storedDriver?.lastLocationRegisteredAt &&
            new Date(storedDriver?.lastLocationRegisteredAt) > yesterday;
          if (storedDriver?.latitude && updatedRecently && map) {
            console.log("settings marker");
            if (driverMarkerRef.current) {
              driverMarkerRef.current.setPosition(
                new google.maps.LatLng({
                  lat: storedDriver.latitude,
                  lng: storedDriver.longitude,
                })
              );
            } else {
              driverMarkerRef.current = new google.maps.Marker({
                position: new google.maps.LatLng({
                  lat: storedDriver?.latitude || 38.70126,
                  lng: storedDriver?.longitude || -9.326099,
                }),
                icon: scooterIcon,
                map,
              });
            }
          }
        });
    }
    if (updatedOrder.status === "completed") {
      orderSubscription();
      driverSubscription();
    }
    setOrder(updatedOrder);
  }

  useEffect(() => {
    if (!map) return;

    var directionsService = new window.google.maps.DirectionsService();
    var directionsRenderer = new window.google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: "#198884",
      },
    });
    var origin = new window.google.maps.LatLng(
      order?.shop?.address.coordinates.latitude || 41.1621356,
      order.shop?.address.coordinates.longitude || -8.6631531
    );
    var destination = new window.google.maps.LatLng(
      order?.address?.coordinates.latitude,
      order.address?.coordinates.longitude
    );
    var request = {
      origin,
      destination,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, function (response, status) {
      if (status == "OK") {
        directionsRenderer.setDirections(response);
        directionsRenderer.setMap(map);
        new window.google.maps.Marker({
          position: {
            lat: order.address.coordinates.latitude,
            lng: order.address.coordinates.longitude,
          },
          icon: houseIcon,
          map,
        });
        if (order.shop) {
          new google.maps.Marker({
            position: {
              lat: order.shop.address.coordinates.latitude || 40.1621356,
              lng: order.shop.address.coordinates.longitude || -8.6631531,
            },
            icon: storeIcon,
            map,
          });
        }
      }
    });
    if (order && order.uid) {
      scuverService
        .getRecord("orders", order.uid)
        .then((savedOrder) => updateOrder(savedOrder));
    }
  }, [map]);

  async function initGoogle() {
    await loadGoogleMapsApi({
      key: GOOGLE_KEY,
    });
    return true;
  }

  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      center: new window.google.maps.LatLng(
        order?.shop?.address?.coordinates?.latitude || 41.1621356,
        order?.shop?.address?.coordinates?.longitude || -8.6631531
      ),
      zoom: 10,
      styles: [
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
      ],
    });
  };

  return (
    <div className={"min-h-screen"}>
      <Header />
      <main className="flex flex-col text-gray-800 ">
        <div ref={googleMapRef} className="w-[99.2vw] h-[60vh]"></div>
        {order ? (
          <div className="w-full md:w-8/12 self-center p-2 md:p-8">
            {order?.status === "pending" ? (
              <div className="flex flex-col md:flex-row justify-center space-x-6 items-center mb-4">
                <span className="text-xl font-roboto">
                  {t("Waiting for Restaurant to Accept")}
                </span>
                <div className="flex flex-col items-center">
                  <span className="text-sm text-gray-600">
                    {t(
                      "If the restaurant does not accept within 10 minutes the order will be cancelled and paid amounts refunded."
                    )}
                  </span>
                  <span className="text-xs text-gray-400">
                    {t(
                      "(MBWay within a few minutes, Card can take up to 3 working days. Contact us if you prefer to cancel de refund and order again.)"
                    )}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            {order?.status === "viewed" ||
            order?.status === "ready" ||
            order?.status === "bringing" ? (
              <div className="flex flex-col md:flex-row justify-center space-x-6 items-center mb-4">
                <span className="text text-gray-500">
                  {t("Estimated Arrival At")}
                </span>
                <span className="text-3xl font-signika">
                  {order.arrivalEstimatedAt
                    ? MyMoment.parse(order.arrivalEstimatedAt).time.toString()
                    : ""}
                </span>
                <span className={"hidden md:flex"}> - </span>
                <span>
                  {order?.status === "viewed" ? "Em Preparação" : ""}
                  {order?.status === "ready"
                    ? "Pronto/Quase Pronto para Recolha"
                    : ""}
                  {order?.status === "bringing" ? "A Entregar" : ""}
                </span>
              </div>
            ) : (
              ""
            )}
            {order?.status === "completed" ? (
              <div className="flex flex-col md:flex-row justify-center space-x-6 items-center mb-4">
                <span className="text text-gray-500">
                  {t(
                    "Your order was delivered. If you want to get in touch with us please use the email address tasticpt@gmail.com or send us a message through whatsapp to +351910932078."
                  )}
                </span>
                <span className="text-3xl font-signika">
                  {t("Order Delivered")}
                </span>
              </div>
            ) : (
              ""
            )}
            {order?.status === "cancelled" ? (
              <div className="flex flex-col md:flex-row justify-center space-x-6 items-center mb-4">
                <span className="text-3xl font-signika">
                  {t("Order Cancelled")}
                </span>
                <span className="text text-gray-500">
                  {t("Your order was cancelled.")}{" "}
                  {order.cancelReason
                    ? order.cancelReason
                    : t(
                        "The restaurant did not accept the order within 10 minutes."
                      )}
                </span>
                {order.paymentMethod !== "payment-on-delivery" ? (
                  <span className="text-base text-gray-800">
                    {order.paymentMethod === "mbw"
                      ? t(
                          "You will receive a refund in the next few minutes. If in 15 minutes you have not received the refund please contact email address tasticpt@gmail.com or send us a message through whatsapp to +351910932078."
                        )
                      : t(
                          "You will receive a refund in the next 3 working days. If in 3 working days you have not received the refund please contact email address tasticpt@gmail.com or send us a message through whatsapp to +351910932078."
                        )}
                  </span>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            <div
              className={"flex justify-center w-full text-center"}
              onClick={() => {
                scuverService
                  .getRecord("orders", order.uid)
                  .then((savedOrder) => updateOrder(savedOrder));
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
            {order?.status === "pending" ||
            order?.status === "viewed" ||
            order?.status === "ready" ||
            order?.status === "bringing" ? (
              <div
                className={
                  "my-4 flex w-[94vw] md:w-[45em] place-self-center mx-auto shadow rounded-full text-xs items-stretch"
                }
              >
                <div
                  className={`flex justify-center items-center text-center p-2 shadow border-gray-600 rounded-l-full ${
                    order.status === "pending"
                      ? "bg-amber-500 text-black animate-pulse"
                      : "bg-white text-gray-400"
                  } w-1/4`}
                >
                  <span className={"middle uppercase text-xs font-bold"}>
                    Por Aceitar
                  </span>
                </div>
                <div
                  className={`flex justify-center items-center text-center p-2 shadow border-l-0 border-gray-600 ${
                    order.status === "viewed"
                      ? "bg-blue-400 text-black animate-pulse"
                      : "bg-white text-gray-400"
                  } w-1/4`}
                >
                  <span className={"uppercase text-xs font-bold"}>
                    Em Preparação
                  </span>
                </div>
                <div
                  className={`flex justify-center items-center text-center p-2 shadow border-l-0 border-gray-600 ${
                    order.status === "ready"
                      ? "bg-lime-500 text-black animate-pulse"
                      : "bg-white text-gray-400"
                  } w-1/4`}
                >
                  <span className={"uppercase text-xs font-bold"}>Pronto</span>
                </div>
                <div
                  className={`flex justify-center items-center p-2 shadow border-l-0 border-gray-600 rounded-r-full ${
                    order.status === "bringing"
                      ? "bg-green-600 text-black animate-pulse"
                      : "bg-white text-gray-400"
                  } w-1/4`}
                >
                  <span className={"uppercase text-xs font-bold"}>
                    A Caminho
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* Address, Phone and Notes */}
            <section className="flex flex-col md:flex-row md:justify-between md:space-x-4">
              <div className="w-full md:w-6/12">
                <div className="flex w-full mt-2 rounded-lg border-gray-400 bg-gray-50 text-gray-800 p-4">
                  <div
                    className={
                      "flex text-gray-800 bg-transparent self-center rounded-full text-center justify-between space-x-2 items-center"
                    }
                  >
                    <MapPinIcon className="w-12" />
                    <div className="flex flex-col">
                      <span className="text-xs md:text-sm">
                        {t("Deliver now")}
                      </span>
                      <span className={"font-bold text-xs md:text-sm"}>
                        {order.address
                          ? `${order.address.addressLine1} ${
                              order.address.addressLine2 || ""
                            } ${order.address.postCode || ""} ${
                              order.address.local || ""
                            }`
                          : t("Address")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col self-start w-full mt-2 rounded-lg border-gray-400 bg-gray-50 text-gray-800 p-4">
                  <span className="text-sm">
                    {t("Phone Number")}
                    <span className="text-[#e64444] text-lg"> *</span>
                  </span>
                  <div className="flex w-full">
                    <PhoneIcon className="w-6 mr-4 mt-2" />
                    <input
                      className="p-2 w-10/12 rounded border-1 border border-gray-300 mt-2 focus:border-transparent focus:ring-0 focus:outline-0 h-full"
                      readOnly
                      disabled
                      value={order?.user?.phoneNumber}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 flex flex-col mt-2 rounded-lg border-gray-400 bg-gray-50 text-gray-800 p-4">
                <span className="text-sm">{t("Notes")}</span>
                <textarea
                  readOnly
                  disabled
                  className="rounded bg-gray-100 border border-gray-300 mt-2 focus:ring-0 h-full"
                  value={order?.notes}
                ></textarea>
              </div>
            </section>
            {/* Payment and Totals */}
            <section className="flex flex-col md:flex-row md:justify-between md:space-x-4">
              <div className="rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:p-8 md:w-6/12 mt-4">
                <h2 id="summary-heading" className="sr-only">
                  {t("Order Summary")}
                </h2>
                <ul role="list" className="divide-y divide-gray-200 border-b ">
                  {order?.orderItems?.map((orderItem) => (
                    <li key={orderItem.uid} className="flex py-4">
                      <div className="flex-shrink-0 w-[5em] hidden md:flex">
                        <Image
                          src={orderItem.photoUrl}
                          classes="border border-[#ccc] w-full h-20 rounded-2xl object-cover w-32"
                        />
                      </div>
                      <div className="flex flex-col justify-between ml-4 w-full ">
                        <div>
                          <div className="flex justify-between">
                            <div className="pr-6">
                              <h3 className="text-sm">
                                <a
                                  href={"#"}
                                  className="font-medium text-gray-700 hover:text-gray-800"
                                >
                                  {orderItem.name} x {orderItem.quantity}
                                </a>
                              </h3>
                              <p className="hidden md:block mt-1 text-sm text-gray-500">
                                {orderItem.description}
                              </p>
                            </div>
                            <div className="flex items-start">
                              <p className="text-right text-sm font-medium text-gray-900 ml-4">
                                €
                                {(orderItem.price * orderItem.quantity).toFixed(
                                  2
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="flow-root">
                  <dl className="-my-4 divide-y divide-gray-200 text-sm">
                    <div className="flex items-center justify-between py-4">
                      <dt className="text-gray-600">{t("Subtotal")}</dt>
                      <dd className="font-medium text-gray-900">
                        €{order?.subTotal?.toFixed(2)}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between py-4">
                      <dt className="text-gray-600">{t("Delivery")}</dt>
                      <dd className="font-medium text-gray-900">
                        €{order?.deliveryFee?.toFixed(2)}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between py-4">
                      <dt className="text-base font-medium text-gray-900">
                        {t("Order total")}
                      </dt>
                      <dd className="text-base font-medium text-gray-900">
                        €{order?.total?.toFixed(2)}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="flex flex-col md:w-6/12 mt-2 rounded-lg border-gray-400 bg-gray-50 text-gray-800 p-4">
                {order &&
                  paymentMethods.map((m) => (
                    <div
                      key={m.value}
                      className={`flex justify-between shadow p-2 items-center border-gray-400 ${
                        m.value === order?.paymentMethod
                          ? "bg-gray-100 ring-1 ring-white ring-opacity-100 ring-offset-2 ring-offset-black-300"
                          : "hidden"
                      }`}
                    >
                      <div className="flex items-center">
                        <img src={m.iconImage} className="w-8" />
                        <span className="uppercase text-sm ml-4">
                          {m.label}
                        </span>
                      </div>
                      <div className="justify-end w-6">
                        {m.value === order?.paymentMethod ? (
                          <CheckCircleIcon className="w-6" color="#27b787" />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </section>
          </div>
        ) : (
          ""
        )}
      </main>
      <Footer />
    </div>
  );
};

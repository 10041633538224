import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button.tsx";
import Footer from "../components/Footer.tsx";
import Header from "../components/Header.tsx";
import Image from "../components/Image.tsx";
import ShopSubHeader from "../components/ShopSubHeader.tsx";
import { Item } from "../models/item.ts";
import { OptionGroup } from "../models/option-group.ts";
import { OrderItem } from "../models/order-item.ts";
import { Order } from "../models/order.ts";
import { Shop } from "../models/shop.ts";
import { Address } from "../models/submodels/address.ts";
import { User } from "../models/user.ts";
import scuverService from "../services/scuver.service.ts";
import { MyMoment } from "../utils/time-helper.ts";
import { UIDGenerator } from "../utils/uid-generator.ts";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import orderHelper from "../utils/order-helper.ts";
import { Option } from "../models/option.ts";
import OptionGroupComponent from "../components/OptionGroup.tsx";
import orderService from "../services/order.service.ts";
import authService from "../services/auth.service.ts";
import addressService from "../services/address.service.ts";
import store from "../state-store.tsx";

export const ItemPage = () => {
  const [item, setItem] = useState({} as Item);
  const [user, setUser] = useState<User>({} as User);
  const [orderItem, setOrderItem] = useState<OrderItem>({} as OrderItem);
  const [address, setAddress] = useState({} as Address);
  const [shop, setShop] = useState({} as Shop);
  const [order, setOrder] = useState<Order>({} as Order);
  const [optionGroups, setOptionGroups] = useState<Array<OptionGroup>>([]);
  const [itemTotal, setItemTotal] = useState(0);
  const [allRequiredOptionsCompleted, setAllRequiredOptionsCompleted] =
    useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    authService.observeCurrentUser().subscribe((usr) => {
      console.log("usr", usr);
      setUser(usr);
    });
    orderService.observeCurrentOrder().subscribe((o) => {
      if (o && o.uid && o.status === "being-created") {
        setOrder(o);
      }
    });
    addressService.observeCurrentAddress().subscribe((a) => setAddress(a));
    if (store.getState("shop")) {
      setShop(store.getState("shop"));
    }
  }, []);

  useEffect(() => {
    if (shop && shop.uid) {
      console.log("SHOP", shop);
      const pathio = location.pathname.substring(
        location.pathname.lastIndexOf("/") + 1
      );
      if (pathio && pathio !== "item") {
        scuverService
          .getRecordsByProperty("shop-categories", "shopId", "==", shop.uid)
          .then((categories) => {
            for (const category of categories) {
              for (const itm of category.items) {
                if (pathio === itm.uid) {
                  setItem(itm);
                  break;
                }
              }
            }
          });
      }
    }
  }, [shop]);

  useEffect(() => {
    if (item.optionGroupsId) {
      populateOptionGroups();
    }
    console.log("item", item);
    setOrderItem({ ...item, optionsSelected: [], quantity: 1 } as OrderItem);
  }, [item]);

  useEffect(() => {
    setItemTotal(orderHelper.getOrderItemTotal(orderItem));
    populateAllRequiredOptionsCompleted(optionGroups, true);
  }, [orderItem]);

  useEffect(() => {
    populateAllRequiredOptionsCompleted(optionGroups, true);
  }, [optionGroups]);

  function populateAllRequiredOptionsCompleted(
    ogs: Array<OptionGroup>,
    completed: boolean
  ) {
    console.log("ogs", ogs);
    console.log("orderItem?.optionsSelected", orderItem?.optionsSelected);
    if (ogs && ogs.length) {
      for (const og of ogs) {
        if (
          og.amountOptionsRequired &&
          !orderItem?.optionsSelected?.find((o) =>
            og.options?.find((ogo) => ogo.name === o.name)
          )
        ) {
          completed = false;
        } else {
          const option = orderItem?.optionsSelected?.find((o) =>
            og.options?.find((ogo) => ogo.name === o.name)
          );
          if (option?.optionGroups && option?.optionGroups.length) {
            populateAllRequiredOptionsCompleted(option.optionGroups, completed);
          } else {
            setAllRequiredOptionsCompleted(true);
          }
        }
      }
    } else {
      setAllRequiredOptionsCompleted(true);
    }
  }

  async function populateOptionGroups() {
    const ogs = [];
    for (const optionGroupId of item.optionGroupsId) {
      ogs.push(
        await scuverService.getRecord("shop-option-groups", optionGroupId)
      );
    }
    setOptionGroups(ogs);

    if (!item.optionGroupsId || !item.optionGroupsId.length) {
      setAllRequiredOptionsCompleted(true);
    }
  }

  const addItem = async () => {
    // let isNewOrder = false;
    let currentUser = null;
    if (user && user.uid) {
      currentUser = user;
    } else {
      await authService.signInAnon();
      currentUser = await authService.getCurrentUser();
      setUser(currentUser);
    }
    let currentOrder = null;

    if (order && order.uid && order.status === "being-created") {
      currentOrder = order;
    } else {
      currentOrder = await orderService.getOrderForThisUserBeingCreated(
        currentUser.uid
      );
    }
    if (
      !currentOrder ||
      (shop && shop.uid && currentOrder.shop?.uid !== shop.uid)
    ) {
      currentOrder = {
        uid: UIDGenerator.generate(),
        status: "being-created",
        orderItems: [],
      } as any as Order;
      currentOrder.log = [];
      currentOrder.shop = JSON.parse(JSON.stringify(shop));
      currentOrder.user = JSON.parse(JSON.stringify(currentUser));
      currentOrder.address = address;
      currentOrder.type = "delivery";
      currentOrder.submittedAt = MyMoment.getCurrentMoment().toString();
      currentOrder.completedAt = "";
      currentOrder.subTotal = 0;
      currentOrder.total = 0;
      currentOrder.deliveryFee = 0;
      currentOrder.isTastic = true;
      currentOrder.platform = "tastic";
      currentOrder.paymentMethod = "mbw";
      // isNewOrder = true;
    } else if (!shop || !shop.uid) {
      console.error("NO SHOP ON ADD ITEM!!!");
    }

    const orderItems = JSON.parse(
      JSON.stringify(currentOrder.orderItems || [])
    );
    const foundItem = orderItems.find((itm) => itm.uid === orderItem.uid);
    if (
      foundItem &&
      JSON.stringify(foundItem.optionsSelected) ===
        JSON.stringify(orderItem.optionsSelected)
    ) {
      foundItem.quantity += orderItem.quantity;
    } else {
      orderItems.push({ ...orderItem } as OrderItem);
    }
    const phoneNumber: string = store.getState("phoneNumber");
    if (
      currentOrder &&
      phoneNumber &&
      currentOrder.user &&
      currentOrder?.user?.phoneNumber !== phoneNumber
    ) {
      currentOrder.user.phoneNumber = phoneNumber;
    }
    currentOrder.orderItems = orderItems;
    setOrder(currentOrder);
    await orderService.setCurrentOrder({ ...currentOrder });
    store.setState("order", { ...currentOrder });
    if (
      currentOrder &&
      currentOrder.uid &&
      currentOrder.status === "being-created"
    ) {
      navigate(`/order/${currentOrder.uid}`);
    } else {
      navigate(`/shop/${shop.uid}`);
    }
  };

  function removeSelectedOptionsofOptionGroup(
    optionGroup: OptionGroup,
    optionsSelected: Array<Option>
  ) {
    for (const ogo of optionGroup.options) {
      if (
        optionsSelected.find(
          (os) => os.name === ogo.name && os.price === ogo.price
        )
      ) {
        optionsSelected.splice(
          optionsSelected.findIndex(
            (os) => os.name === ogo.name && os.price === ogo.price
          ),
          1
        );
      }
      if (ogo.optionGroups && ogo.optionGroups.length) {
        for (const ogoog of ogo.optionGroups) {
          optionsSelected = removeSelectedOptionsofOptionGroup(
            ogoog,
            optionsSelected
          );
        }
      }
    }
    return optionsSelected;
  }

  function selectOption(option: Option, optionGroup: OptionGroup) {
    let optionsSelected = JSON.parse(JSON.stringify(orderItem.optionsSelected));
    if (optionGroup.type === "pickable") {
      optionsSelected = removeSelectedOptionsofOptionGroup(
        optionGroup,
        optionsSelected
      );
    }
    optionsSelected.push({ ...option, quantity: 1 });
    setOrderItem({ ...orderItem, optionsSelected });
    setItemTotal(orderHelper.getOrderItemTotal(orderItem));
  }

  function removeOption(option) {
    setOrderItem({
      ...orderItem,
      optionsSelected: [
        ...(orderItem.optionsSelected || []).filter(
          (o) => o.name !== option.name
        ),
      ],
    });
    setItemTotal(orderHelper.getOrderItemTotal(orderItem));
  }

  function handleOptionSelectedEvent(e, option, optionGroup) {
    if (
      optionGroup.type === "pickable" ||
      e.target.parentNode.querySelector("input").checked
    ) {
      selectOption(option, optionGroup);
      e.target.parentNode.querySelector("input").checked = true;
      e.target.parentNode
        .querySelector("input")
        .setAttribute("checked", "true");
    } else {
      removeOption(option);
      e.target.parentNode.querySelector("input").removeAttribute("checked");
      e.target.parentNode.querySelector("input").checked = false;
    }
    return true;
  }

  return (
    <div className={"h-screen"}>
      <Header />
      <div className="hidden md:block">
        <ShopSubHeader />
      </div>
      <div
        className="md:m-4 text-primary-500 flex cursor-pointer"
        onClick={() => navigate(`/shop/${shop.uid}`)}
      >
        <ChevronLeftIcon className="w-6" />
        <span>{t("Back")}</span>
      </div>
      <main className="flex w-full p-4 text-gray-800 my-4 min-h-[42vh]">
        <div className="flex flex-col md:flex-row w-full">
          <div className="w-full md:w-4/12 mr-4">
            <Image
              src={item && item.photoUrl}
              classes="w-full h-52 md:h-auto rounded-2xl object-cover border border-[#ccc]"
            />
            <div className="flex justify-start space-x-4 m-4 mt-8">
              <div className="flex items-center rounded-full bg-primary-600 text-white">
                {/*<span className='hidden 2xl:block'>{t('Quantity')}</span>*/}
                <Button
                  onClick={() =>
                    setOrderItem({
                      ...orderItem,
                      quantity:
                        orderItem.quantity > 1 ? orderItem.quantity - 1 : 1,
                    })
                  }
                  text={"-"}
                />
                <span>{orderItem.quantity || 1}</span>
                <Button
                  onClick={() =>
                    setOrderItem({
                      ...orderItem,
                      quantity: orderItem.quantity + 1,
                    })
                  }
                  text={"+"}
                />
              </div>
              <div className="self-center">
                <Button
                  disabled={!allRequiredOptionsCompleted}
                  onClick={() => addItem()}
                  text={`${t("Add")} €${itemTotal.toFixed(2)}`}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-auto w-full mt-4">
            <div>
              <h3 className="font-bold lg:text-lg text-gray-800">
                {item.name}
              </h3>
              <span className="text-gray-600">
                {item.description ||
                  t("The items description is provided by the restaurant.")}
              </span>
            </div>
            {optionGroups && optionGroups.length
              ? optionGroups.map((optionGroup) => (
                  <OptionGroupComponent
                    key={optionGroup.uid}
                    optionGroupProp={optionGroup}
                    optionsSelected={orderItem.optionsSelected}
                    handleOptionSelectedEvent={handleOptionSelectedEvent}
                  />
                ))
              : ""}
          </div>
        </div>
      </main>
      <div className="hidden md:block">
        <Footer />
      </div>
    </div>
  );
};

// import sandwich from '../assets/images/sandwich_primary.svg';
import logoAndText from "../assets/images/logo_and_text.svg";
// import logo from '../assets/images/logo.svg';
import { Capacitor } from "@capacitor/core";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  useAuth,
  UserButton,
  useUser,
} from "@clerk/clerk-react";
import {
  ArchiveBoxXMarkIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { signInWithCustomToken } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase.tsx";
import { Order } from "../models/order.ts";
import { Address } from "../models/submodels/address.ts";
import { User } from "../models/user.ts";
import addressService from "../services/address.service.ts";
import authService from "../services/auth.service.ts";
import orderService from "../services/order.service.ts";
import scuverService from "../services/scuver.service.ts";
import AddressSelection from "./AddressSelection.tsx";
import Input from "./Input.tsx";

const Header = () => {
  const navigate = useNavigate();
  const [, setUser] = useState<User>({} as User);
  const signedInRef = useRef(false);
  const signedOutRef = useRef(false);
  const [address, setAddress] = useState<Address>({} as Address);
  const [order, setOrder] = useState<Order>({} as Order);
  const [displayAddresses, setDisplayAddresses] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { t } = useTranslation();
  const { getToken, userId } = useAuth();
  const { user: clerkUser } = useUser();

  useEffect(() => {
    authService.observeCurrentUser().subscribe((usr) => {
      setUser(usr);
    });
    orderService.observeCurrentOrder().subscribe((o) => setOrder(o));
    //store.observeState('order').subscribe((o) => setOrder(o));
    addressService.observeCurrentAddress().subscribe((a) => setAddress(a));

    console.log("userId", userId);
  }, []);

  useEffect(() => {
    if (signedOutRef.current) {
      authService.signOut();
    }
  }, [signedOutRef.current]);

  useEffect(() => {
    if (signedInRef.current && !signedOutRef.current) {
      signIntoFirebaseWithClerk();
    }
  }, [signedInRef.current]);

  useEffect(() => {
    if (
      clerkUser &&
      clerkUser.emailAddresses &&
      clerkUser.emailAddresses.length
    ) {
      scuverService
        .getRecordByProperty(
          "users",
          "email",
          "==",
          clerkUser.emailAddresses[0].emailAddress
        )
        .then((result) => {
          const user = result.length ? result[0] : null;
          console.log("user", user);
          if (user) {
            authService.setUser(user as User);
          } else {
            console.log("CREATING USER", clerkUser);
            const user = {} as User;
            user.uid = clerkUser?.id;
            user.email = clerkUser?.emailAddresses[0]?.emailAddress;
            user.phoneNumber = clerkUser?.phoneNumbers[0]?.phoneNumber;
            user.name = clerkUser?.fullName;
            authService.setUser(user as User);
            scuverService.addOrUpdateRecord("users", user);
          }
        });
    } else {
      console.warn("No clerk user or no email address");
    }
  }, [clerkUser]);

  async function signIntoFirebaseWithClerk() {
    const token = await getToken({ template: "integration_firebase" });
    if (!token) {
      console.warn("No Firebase token received from Clerk");
    }
    console.log("token", token);

    if (token) {
      const userCredentials = await signInWithCustomToken(auth, token || "");
      // The userCredentials.user object can call the methods of
      // the Firebase platform as an authenticated user.
      console.log("User:", userCredentials.user);
      console.log("clerkUser:", clerkUser);
      console.log("userId:", userId);
    }
  }

  function checkSignedOut() {
    if (!signedOutRef.current) {
      signedOutRef.current = true;
      signedInRef.current = false;
    }
    return <></>;
  }

  function checkSignedIn() {
    if (!signedInRef.current) {
      signedInRef.current = true;
      signedOutRef.current = false;
    }
    return <></>;
  }

  return (
    <header
      className={`flex flex-col justify-between stick pt-3 pb-1 ${
        Capacitor.getPlatform() === "ios" ? "mt-8" : ""
      }`}
    >
      <div className="flex flex-col md:flex-row justify-between pl-4 pr-1 pb-2">
        <div
          className="flex cursor-pointer"
          onClick={() => (window.location.href = "/")}
        >
          <img className="w-[8.5em]" src={logoAndText} />
          {/* <img className="hidden md:block w-[10em]" src={logoAndText}/>
        <img className="md:hidden w-[3em]" src={logo}/> */}
        </div>
        <div className="flex space-x-6 align-middle justify-end md:items-center">
          {/* <div className={'text-gray-800 bg-gray-200 md:self-center rounded-full text-center flex justify-between space-x-2 mt-4 py-2 pr-2 pl-4 md:p-4 cursor-pointer'} onClick={() => setDisplayAddresses(true)} text={t('Change')}>
          <span className='text-sm'>{t('Deliver now')}</span>
          <span className={'font-bold'}>{address ? (address.local) : t('No Address Selected')}</span>
          <ChevronRightIcon className='w-4 md:w-6'/>
        </div> */}
          {!window.location.href.includes("order") &&
          order &&
          order.orderItems &&
          order.orderItems.length &&
          order.status === "being-created" ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                console.log("Navigating to order", order?.uid);
                navigate(`/order/${order?.uid}`);
                return false;
              }}
              className={
                "bg-primary-600 p-2 md:p-4 text-white rounded-full border-0 cursor-pointer hover:opacity-90 w-[8em] mt-4 md:mt-0 md:absolute right-[24em] md:w-[10em] mr-2"
              }
            >
              <ShoppingBagIcon className="h-6 w-6 mr-4 inline-block relative bottom-0.5" />{" "}
              {order.orderItems.length
                ? order.orderItems.reduce(
                    (total, item) => total + item.quantity,
                    0
                  )
                : ""}{" "}
              {t("Items", {
                s:
                  order.orderItems.reduce(
                    (total, item) => total + item.quantity,
                    0
                  ) > 1
                    ? "s"
                    : "",
              })}
            </button>
          ) : (
            ""
          )}
          <Input
            type="text"
            icon={
              <MagnifyingGlassIcon className="text-gray-600 h-6 w-6 relative top-2 left-2" />
            }
            placeholder={t("Search restaurants or items")}
            classes="hidden lg:flex self-center !bg-gray-200 !p-1.5 mt-0 w-[30vw] !mt-0"
            inputClasses="!text-base !text-black !pl-4"
            initialValue={searchText}
            onChange={(e) => setSearchText((e.target as any).value)}
            onEnter={() => {
              navigate(`/home?search=${searchText}`, { replace: true });
            }}
          />
        </div>
        <div className="absolute right-1 md:right-0 flex md:relative">
          <div
            className={
              "flex text-gray-800 bg-gray-200 p-2 md:p-4 rounded-full cursor-pointer w-[8em] md:w-[18em] mr-4 justify-between items-center"
            }
            onClick={() => setDisplayAddresses(true)}
          >
            <span className="hidden md:inline text-sm">{t("Deliver now")}</span>
            <span className={"font-bold truncate text-sm max-w-"}>
              {address ? address.local : t("Address")}
            </span>
            <ChevronRightIcon className="w-6 h-6" />
          </div>
          <div className="flex items-center mr-4">
            <SignedOut>
              {checkSignedOut()}
              <div className="rounded-full p-2 bg-primary-600">
                <SignInButton mode="modal">&nbsp;Login&nbsp;</SignInButton>
              </div>
            </SignedOut>
            <SignedIn>
              {checkSignedIn()}
              <UserButton>
                <UserButton.MenuItems>
                  <UserButton.Link
                    label="Histórico de pedidos"
                    labelIcon={<ArchiveBoxXMarkIcon className="w-4 h-4" />}
                    href="/order-history"
                  />
                </UserButton.MenuItems>
              </UserButton>
            </SignedIn>
          </div>
          {/* {user ? (
            <button
              id="button-avatar"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                store.setState("userMenuOpen", true);
                return false;
              }}
              className={
                "bg-gray-200 w-10 h-10 px-3 rounded-full border-0 cursor-pointer hover:opacity-90 inline-block relative"
              }
            >
              <img src={avatar} />
            </button>
          ) : (
            ""
          )}
          <UserMenu /> */}
        </div>
      </div>
      <AddressSelection
        currentAddress={address ? address.addressLine1 : null}
        show={displayAddresses}
        onClose={() => setDisplayAddresses(false)}
      />
    </header>
  );
};

export default Header;
